// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FaUserAlt, FaHome, FaShoppingBag, FaTruck, FaHandshake } from 'react-icons/fa';

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'Actors',
  title: '',
  icon: FaHome,
  type: 'group',
  children: [
    {
      id: 'Dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: FaHome,
      breadcrumbs: false
    },
    {
      id: 'Sub Admin',
      title: <FormattedMessage id="Sub Admin" />,
      type: 'collapse',
      icon: FaUserAlt,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/sub-admin'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/sub-admin/manage'
        }
      ]
    },
    {
      id: 'Partner',
      title: <FormattedMessage id="Partner" />,
      type: 'collapse',
      icon: FaHandshake,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/vendor'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/vendor/manage'
        }
      ]
    },
    {
      id: 'In Store',
      title: <FormattedMessage id="In Store" />,
      type: 'collapse',
      icon: FaShoppingBag,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/franchise'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/franchise/manage'
        }
      ]
    },
    {
      id: 'Wheelz',
      title: <FormattedMessage id="Wheelz" />,
      type: 'collapse',
      icon: FaTruck,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/wheelz'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/wheelz/manage'
        }
      ]
    }
  ]
};

export default widget;
